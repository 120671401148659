.Header {
  background-color: var(--primary-color);
  text-align: center;
  font-size: 1.5em;
  text-transform: uppercase;
  width: 100%;
  height: 100px;
  padding-top: 1em;
  color: var(--secondary-color);
}

.ContactRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
}

.descriptionHeader,
.EmailHeader,
.LocationHeader,
.ProposalHeader,
.mapContainer {
  flex: 1;
  margin: 2em 3em;
  /* max-width: 100%; */
}

.descriptionHeader {
  max-width: 30em;
  font-size: 1.2em;
  line-height: 2em;
  text-align: right;
  color: var(--text-color);
}

.EmailHeader,
.LocationHeader,
.ProposalHeader {
  color: var(--secondary-color);
  max-width: 40em;
  max-height: 15vh;
  margin-top: 2.5em;
  max-height: none;
  text-align: center;
}

.subHead {
  font-size: 1.5em;
  text-transform: uppercase;
}

.mapContainer {
  position: relative;
  height: 300px;


}

/* Style for the map image */


.media {
  text-align: center;
  padding-right: 6em;
  margin-top: 10em;
}

h2 {
  color: var(--secondary-color);
  font-size: 2em;
  text-transform: uppercase;
  padding: 0.5em;
  /* Adjust padding as needed */
}

.socialIcons {
  display: flex;
  justify-content: center;
}

.socialIcons a {
  margin: 2em 0.5em;
  /* Adjust margin as needed */
}

.socialIcons a svg {
  font-size: 3em;
  color: var(--primary-color);
}

.para {
  margin-top: 1em;
  font-size: 1.2em;
  color: var(--text-color);
}

/* Media Queries for responsive design */
@media only screen and (max-width: 1200px) {
  .ContactRow {
    justify-content: center;
    /* Center items on medium screens */
  }

  .media {
    text-align: center;
    margin-top: 10em;
  }


}

/* Medium screens ----------- For tablets: */



@media only screen and (max-width: 899px) {
  .ContactRow {
    flex-direction: column;
    align-items: center;
  }
}

/* For small screens ----------For Mobile */

@media only screen and (max-width: 767px) {
  .ContactRow {
    margin: 0;
  }

  .mapContainer {
    height: 200px;
    width: 100%;
    /* Set full width */
    margin-top: 2em;
    align-self: flex-start;


  }
}