.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
  background: #f3f0f0;
  position: fixed;
  z-index: 99;
  width: 100%;
  opacity: 1;
  top: 0;
}
.logo {
  cursor: pointer;
  float: right;
  display: block;
  font-size: 50px;
  position: relative;
  padding-left: 2rem;
  padding-right: 15px;
  font-weight: 900;
  height: auto;
}

.headerMenu {
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 0.1rem;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 1rem;

  font-weight: 500;
  justify-content: flex-end;
}

.headerMenu li {
  position: relative;
  color: var(--primary-color) !important;
  height: 3rem;
  padding-bottom: 0 !important;
}
.headerMenu li a {
  text-decoration: none;
}
.headerMenu li:hover {
  border-color: var(--text-color) !important;
}
.secondMenu {
  border: 0;
  height: 3rem;
  justify-content: flex-end;
  font-weight: 700 !important;
  background-color: transparent;
}
.secondMenu {
  color: var(--primary-color) !important;
}
.secondMenu:hover {
  border-color: var(-text-color);
}
.toggleButton {
  display: none; /* Initially hide the toggle button */
}
.ant-menu-submenu-title {
  color: var(--primary-color) !important;
  border: 8em solid #222 !important;
  font-size: 1rem;
}
@media (max-width: 768px) {
  .logo {
    cursor: pointer;
    float: right;
    display: block;
    font-size: 40px;
    position: relative;
    padding-left: 2rem;
    padding-right: 15px;
    font-weight: 900;
    height: auto;
  }

  .toggleButton {
    display: block; /* Show the toggle button on smaller screens */
  }

  .headerMenu {
    display: none; /* Hide the menu items by default on smaller screens */
    flex-direction: column;
  }

  .headerMenu.menuOpen {
    display: flex; /* Show the menu items when the menu is open */
  }

  .menuPopup {
    border: 2px solid #222;
    font-size: 1rem;
  }
}
