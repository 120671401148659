/* .aboutContainer {
} */
.aboutContainer h1 {
  width: 100%;
  color: var(--secondary-color);
  text-shadow: 0 0 2px rgba(255, 255, 255, 0.8);

  padding: 2em 7em;
  font-size: 3em;
  height: 5em;
  text-transform: uppercase;
  /* text-align: center; */
  background-color: var(--primary-color);
}

.aboutGrid {
  margin: 4em 20em;

  display: grid;
  /* border: 2px solid #222; */
  animation: slideContent 0.5s linear 0.6s backwards;
  grid-template-columns: 20em auto 30em;
}

.aboutGridItem p {
  font-size: 1.5em;
  margin-top: 1em;
  text-align: justify;
  line-height: 1.5em;
  color: var(--text-color);
}

.aboutGridItem h2 {
  font-size: 2.2em;
  text-transform: uppercase;
  color: var(--primary-color);
}

.aboutGridItem {
  margin: 0 2em;
}

@keyframes slideContent {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
}




/* For large screens */



@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .aboutContainer h1 {
    padding: 2em 7em;
    font-size: 2.5em;
  }

  .aboutGridItem h2 {
    font-size: 2em;

  }

  .aboutGrid {
    margin: 4em 5em !important;
  }

  .aboutGridItem p {
    font-size: 1em;
  }
}

/* Medium screens ----------- For tablets: */
@media only screen and (max-width:899px) and (min-width: 768px) {
  .aboutContainer h1 {
    padding: 2em 5em;
    font-size: 2.5em;
  }

  .aboutGrid {
    grid-template-columns: auto;
    margin: 2em 10em !important;
  }
}

/* For small screens ----------For Mobile */
@media only screen and (max-width: 767px) {
  .aboutContainer h1 {
    padding: 2em;
    font-size: 2em;
  }

  .aboutGrid {
    margin: 2em !important;
    grid-template-columns: auto;
    grid-row-gap: 2em;
  }

  .aboutGridItem {
    margin: 0;
  }

  .aboutGridItem p {
    font-size: 1.2em;
    margin-top: 0.5em;
  }

  .aboutGridItem h2 {
    font-size: 1.8em;
  }
}