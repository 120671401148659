.aboutContainer {
  position: relative;
  animation: slideContent 0.5s linear 0.6s backwards;
  display: flex;
  padding: 1em;

  overflow: hidden;
  /* border: 5px solid rgb(145, 145, 144) !important */
}

.aboutContent {
  text-align: left;
  width: 100%;
  /* border: 5px solid red !important */
}

.carousel {
  /* border: 5px solid red !important; */
  max-width: 400px;
  margin-top: 5em;
  margin-left: 5em;
  overflow: hidden;
  height: 200px;
}


@keyframes slideContent {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

.aboutContent span,
.aboutContent div {
  white-space: normal;
  text-align: justify;
  animation: slideContent 1s linear 0.8s backwards;

  color: var(--text-color);
  font-size: 1.2em;
}

.aboutContent h1 {
  color: var(--primary-color);
  font-size: 38px;

}

.aboutMenu {
  background-color: transparent;
  border: none !important;
}

.aboutMenu li {
  color: var(--text-color);
  border: none !important;
}

.aboutMenu li svg {
  color: var(--secondary-color);
  font-size: 1.2rem;
}

.aboutContent h3 {
  color: var(--text-color);
}


.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* This ensures the image covers the entire space without stretching */
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .aboutContent h1 {
    font-size: 38px
  }


  .aboutContainer {
    flex-direction: column;
  }

  .carousel {
    max-width: 100%;
    margin: 2em auto;
  }
}

/* Medium screens ----------- For tablets: */
@media only screen and (max-width:899px) {

  .aboutContent h1 {
    font-size: 38px
  }

  .aboutContainer {
    flex-direction: column;
  }

  .aboutContent span,
  .aboutContent div {

    font-size: 1.1em;
  }




}

/* For small screens ----------For Mobile */
@media only screen and (max-width: 767px) {

  .aboutContent h1 {
    font-size: 32px
  }

  .aboutContainer {
    flex-direction: column;
    padding: 0.5em;
  }

  .aboutContent span,
  .aboutContent div {
    font-size: 1em;
  }

  .carousel {
    max-width: 100%;
    margin: 1em auto;
    margin-left: 0;
  }
}