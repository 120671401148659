.serviceContainer {
  margin: 1em 20em 5em 20em;

}

.serviceContainer h1 {
  margin: 1em;
  font-size: 2.5em;
  color: var(--primary-color);
  text-align: left;
}

.serviceCard {
  /* border: 2px solid var(--primary-color); */
  /* margin-top: 2em; */
  padding: 1em !important;
}

.serviceCard p {
  line-height: 1.5em;
  font-size: 1.2em;
  text-align: justify;
  color: var(--text-color);
  margin-bottom: 0.5em;

  text-transform: inherit;
  /* height: 120px; */
}

.serviceCardTitle img {
  margin: 1em 0;
  /* width: 30px;       */
  height: 100% !important;
}

.serviceCardLinks {
  list-style: none;
  text-align: left;
  font-size: 1.2em;
}

.serviceCardLinks li a {
  line-height: 1.5em;
  color: var(--text-color);
}

.serviceCardLinks li a:hover {
  text-decoration: underline;
}


@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .serviceContainer {
    margin: 1em 10em 3em 10em;
  }
}

/* Medium screens ----------- For tablets: */
@media only screen and (max-width:899px) {

  .serviceContainer {
    margin: 1em 5em 3em 5em;
  }
}

/* For small screens ----------For Mobile */
@media only screen and (max-width: 767px) {

  .serviceContainer {
    margin: 1em 2em 3em 2em;
  }

  .serviceCard {
    margin-top: 2em;
    margin-left: 1em;
  }
}