.homeContent {
  padding: 1em 20em 5em 20em;
}

@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .homeContent {
    padding: 5em 10em 2em 10em;
  }
}

/* Medium screens ----------- For tablets: */
@media only screen and (max-width:899px) and (min-width: 768px) {
  .homeContent {
    padding: .5em 10em 2em 10em;
  }
}

/* For small screens ----------For Mobile */
@media only screen and (max-width: 767px) {
  .homeContent {
    padding: 0
  }
}