.subServiceContainer {
  display: flex;
  flex-direction: row;
  margin: 5em 20em 1em 20em;
}

.subServiceHead {
  text-align: center;
  padding-top: 1em;
  font-size: 1.5em;
  text-transform: uppercase;
  width: 100%;
  height: 100px;
  color: #fff;
}

.subServiceContainer h1 {
  margin-bottom: 2em;
  font-size: 1.5em;
  text-transform: uppercase;
}

.subServiceDefine,
.subServiceSession {
  width: 50%;
  padding: 2em;
}

.subServiceSession h1 {
  font-size: 2em;
}

.subServiceSession ul {
  list-style: none;

  text-align: justify;
}

.subServiceSession ul li {
  margin: 2em 0;
  font-size: 1.2em;
  line-height: 1.5em;
}

.label {
  margin-bottom: 1em;
  font-size: 1.2em;
  font-weight: bold;
}

.subServiceDefine p {
  color: var(--text-color);
  font-size: 1.5em;
  text-align: justify;
  line-height: 1.5em;
}

.formContainer {
  margin: 5em 20em 1em 20em;
  background: #fff;
  padding: 2em;
  text-align: center;
}

.formContainer h3 {
  text-align: center;
  font-size: 1.5em;
}

.carousel {
  text-align: center !important;
}

.carousel h3 {
  margin: 1.5em 0;
}

.subServiceDefine h1 {
  font-size: 2em;
  margin-bottom: 2em;
}

.subServiceSession ul {
  list-style-type: none;
}

.subServiceSession ul li {
  list-style-type: none;
  margin: 1em 0;
}

.cardContainer {
  display: flex !important;
  flex-direction: row;
  text-align: justify;
  cursor: pointer !important;
  width: 100%;
}

.cardContainer .card {
  /* width: 25%; */
  margin: 1em;
}

.Form {
  margin: 5em 20em 1em 20em;
  height: 30em;
}