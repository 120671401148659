.heading {
  background-color: var(--primary-color);
  text-align: center;
  padding-top: 2em;
  font-size: 1.5em;
  text-transform: uppercase;
  width: 100%;
  height: 100px;
  color: #fff;
}

.careerContainer {
  display: flex;
  align-items: center;
  padding: 5em;
  justify-content: center;
  /* justify-content: space-between; */
}

.careerContainer p {
  margin-left: 1em;
  color: var(--text-color);
  flex: 0.5 1;
  text-align: justify;
  /* font-weight: lighter; */
  font-size: 1.8em;
  line-height: 1.5em;
}