/* Global styles */
.GridContainer {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 0.5em;
}

.formContainer {
  width: 25%;
  margin-top: 1em;
}

.ServiceHead {
  max-width: 100%;
  color: #c8b583 !important;
  padding: 1em 0.5em;
  font-size: 2em !important;
  height: 5em;
  text-transform: uppercase;
  background-color: var(--primary-color);
}

.formContainer h2 {
  color: #f5f5dc;
  text-transform: uppercase;
}

/* Service Grid styles */
.ServiceContainer {
  display: flex;
  flex-wrap: wrap;

}

.serviceGrid {
  border-radius: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin: 1em;
  border: 1px solid #ccc;
}

.serviceGrid h2 {
  font-size: 2em;
  text-transform: uppercase;
}

.serviceGrid h2:hover {
  text-decoration: underline;
}

.serviceGrid ul {
  list-style: none;
  margin-top: 0.5em;
}

.serviceGrid ul li a {
  color: #f5f5dc;
  font-size: 1.2em;
  line-height: 2em;
  text-transform: uppercase;
}

.serviceGrid li {
  margin-top: 5px;
}

.serviceGrid ul li a:hover {
  text-decoration: underline;
}

/* Form Container styles */
.formContainer {
  flex: 0 0 calc(25% - 2em);
  margin-top: 1em;
}

/* Ant Carousel styles */
.ant-carousel {
  flex: 0 0 calc(25% - 2em);
  margin: 1em;
  border: 2px solid #222;
}

/* Carousel styles */
.carousel {
  width: 100%;
  text-align: center;
  padding-top: 5em;
  background-color: transparent !important;
}

.carousel h3 {
  margin-bottom: 1em;
  color: var(--primary-color);
}

/* Media Queries for responsive design */
@media only screen and (max-width: 1200px) and (min-width: 900px) {

  .GridContainer {
    grid-template-columns: repeat(2, auto);
  }

  .formContainer,
  .ant-carousel {
    flex: 0 0 calc(50% - 2em);
  }
}

/* Medium screens ----------- For tablets: */
@media only screen and (max-width: 899px) and (min-width: 768px) {
  .GridContainer {
    grid-template-columns: repeat(1, auto);
  }

  .formContainer,
  .ant-carousel {
    flex: 0 0 calc(50% - 2em);
    margin: 1em 0;
  }
}

/* For small screens ----------For Mobile */
@media only screen and (max-width: 767px) {
  .GridContainer {
    grid-template-columns: repeat(1, auto);
  }

  .formContainer,
  .ant-carousel {
    flex: 0 0 calc(50% - 2em);
    margin: 1em 0;
  }
}