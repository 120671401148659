* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Helvetica' !important;
}

@import url('https://fonts.googleapis.com/css2?family=Helvetica:wght@400;700&display=swap');

:root {
  --primary-color: rgb(159, 29, 43);
  --secondary-color: #c2a077;
  --text-color: #6d6d6d;

  /* --font-family: 'Helvetica' !important; */
  html,
  body {
    width: 100%;

    font-family: 'Helvetica' !important;
  }
}

.Header {
  padding: 0;
  margin: 0;
}

.content {
  margin-top: 2rem;
}

.footer {
  padding: 0;
  color: var(--secondary-color);
  margin: 0;
}

.FloatButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  /* Increase width */
  height: 100px;
  /* Increase height */
  padding: 0 !important;
  border-radius: 50%;
  /* Ensure the button is a circle */
  background-color: green;
  /* Change background color as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* Add shadow for a floating effect */
}

.Icon {
  color: white;
  /* Change icon color */
  font-size: 2em;
  /* Increase font size */
}