.InsightContainer {
  display: grid;
  margin: 5em 0;
  justify-content: center;
  grid-template-columns: auto auto auto;
  column-gap: 2em;
  row-gap: 2em;
  /* padding: 10px; */
}
.InsightContainer .Card {
  width: 25em !important;
  height: auto;
}
.Header {
  display: flex; 
  padding: 0 22em;
  background-color: var(--primary-color);
  justify-content: center;
}
.InsightContainer .Card img {
  max-width: 20em !important;
  margin: 2em;
  max-height: 15em;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: contain;
}
.InsightHeader {
  text-align: left;
  padding-top: 1em;
  font-size: 1.5em;
  margin: 0 5em !important;
  text-transform: uppercase;
  width: 100%;
  height: 100px;
  color: #fff;
}

.selectedCategory {
  margin: 2.5em 7em;
}
.selectedCategory .ant-select-selector {
  background-color: var(--primary-color) !important;
}
.Pagination {
  text-align: center;
  margin: 2em;
}
.description {
  text-align: center;
}
