.footer {
  background-color: var(--primary-color);
  justify-content: center;
  width: 100%;
}

.footerSection {
  display: flex;
  height: auto;
  width: 100%;
  justify-content: center;
  padding: 2rem 5rem;
}

.footerCol {
  margin: 2rem;
}

.footerCol h3 {
  color: var(--secondary-color);
  font-size: 1.5rem;
  font-weight: 500;
}

.footerMenu {
  justify-content: left;
  margin-top: 0.8rem;
  font-weight: 200;
}

.footerMenu li {
  list-style: none;
  margin: 0 !important;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
  /* border: 2px solid #222; */
  padding-top: 0.1rem;
  width: 300px;

}

.footerMenu li a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.footerMenu li a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer p {
  color: #c0707d;
  text-align: center;
  padding: 1rem 0;
}

.socialIcons a {
  font-size: 2rem;
  color: #c0707d;
  padding: 0.1rem;
}

.socialIcons {
  text-align: center;
  /* align-items: right; */
  margin-left: 7rem;
  /* max-width: 600px; */
}


@media only screen and (max-width: 1200px) and (min-width: 900px) {
  .footerSection {
    padding: 2rem;
    height: 350px;
  }

  .footerCol {
    margin: 2rem;
    font-size: 0.8em;
  }

  .footerMenu li {

    font-size: 0.8rem !important;

  }

  .socialIcons {
    margin-left: 0;
  }
}

/* Medium screens ----------- For tablets: */
@media only screen and (max-width:899px) {
  .footerSection {
    flex-direction: column;
    align-items: center;
    /* flex-wrap: wrap; */
  }

  .footerCol {
    margin: 1rem 0;
  }

  .socialIcons {
    margin-left: 0;
  }



}

/* For small screens ----------For Mobile */
@media only screen and (max-width: 767px) {
  .footerSection {
    flex-direction: column;
    align-items: center;

  }

  .footerCol {
    margin: 1rem 0;
  }

  .socialIcons {
    margin-left: 0;
  }

}