/* CSS styles for hero.module.css */

.serviceContainer {
  background: url('https://picsum.photos/id/1/300/300');
  background-size: cover;
  background-position: center;
}

.menuContainer {
  display: flex;
  flex-direction: column;
}

.hamburgerButton {
  display: none;
}

.flipCard {
  width: 300px;
  height: 300px;
  color: #222;
  perspective: 1000px;
  border: 0;
  opacity: 0.9;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: visibility 0.6s;
}

.flipCardFront {
  font-size: 2rem;
  padding: 20px;
  visibility: visible;
}

.flipCardFront h3 {
  color: #fff;
  font-size: 1.5rem;
}

.flipCard:hover .flipCardFront {
  visibility: hidden;
}

.flipCardFront Img {
  align-items: center;
  margin-top: 30px;
  width: 100%;
}

.flipCardBack {
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
}

.flipCardBackMenu {
  display: flex;
  flex-direction: column;
}

.showMenu {
  display: block !important;
}

.flipCardBackMenu li {
  padding: 0 !important;
  margin: 0 !important;
  min-width: 20em !important;
  width: 100%;
  height: 2em !important;
}

.flipCardBack ul li a {
  color: white;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 0 !important;
  text-decoration: none;
  list-style: none;
}

.flipCardBack ul li a:hover {
  text-decoration: underline;
}

.flipCardBack ul li {
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 0.1rem !important;
}

.flipCardBack h3 {
  color: #baa773;
  width: 100%;
  text-align: center;
  font-size: 1.7em;
  margin-left: 0.1rem;
}

.imageContainer {
  position: relative;
  overflow: hidden;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageContainer:hover .imageOverlay {
  opacity: 1;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px;
  background-color: #fff;
  color: #000;
  border: none;
  cursor: pointer;
  outline: none;
}

.image {
  transition: transform 0.3s ease;
}

.imageContainer:hover .image {
  transform: scale(1.1);
  opacity: 0;
}

.imageWrapper {
  position: relative;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.9s ease;
  font-size: 0.8em;
}

.imageContainer:hover .overlay {
  opacity: 1;
}

.imageContainer:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: var(--primary-color);
}

.imageContainer:hover .imageWrapper img {
  filter: brightness(80%) saturate(10%);
  opacity: 1;
}

.hoverButton Button {
  background-color: var(--primary-color);
  border: var(--primary-color);
  color: var(--secondary-color);
  text-transform: uppercase;
  top: 50%;
  font-size: 1.2em;
  font-weight: bold;
  width: 30%;
  height: 10%;
  position: absolute;
}

.hoverButton .button1 {
  left: 15%;
}

.hoverButton .button2 {
  right: 15%;
}

.hoverButton Button:hover {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
}

/* Large screens ------------ For desktop: */
@media only screen and (min-width: 768px) {}

/* Medium screens ----------- For tablets: */
@media only screen and (min-width: 768px) and (max-width: 1199px) {}

/* For small screens ----------For Mobile */
@media only screen and (min-width: 767px) {}