.ServiceHero {
  display: flex;
  justify-content: space-between;
}

.ServiceLogo {
  width: 25%;
  padding: 4em;
  height: auto;
  text-align: center;
}

.ServiceLogo h3 {
  color: #fff;
  text-transform: uppercase;
}

.ServiceHero p {
  text-transform: capitalize;
  width: 50%;
  margin-right: 1em;
  text-align: justify;
  color: #fff;
  font-size: 2em;

  padding-top: 4em;
}

.ServiceHero p a {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.ServiceHero p a:hover {
  color: #fff;
  cursor: pointer;
}

.ServicesContainer {
  display: flex;
  margin-top: 2em;
}

.auditServiceGrid {
  display: grid;
  gap: 0.1em;
  margin: 0 10em 0 5em;
  grid-template-columns: repeat(3, auto);
}

.auditGridItem {
  /* border: 2px solid red; */
  height: 350px;
  width: auto;
  cursor: pointer;
  border-radius: 2em;
  background-color: rgb(255, 255, 255);
  margin: 0.5em;
  padding: 0.5em;
}

.auditGridItem a {
  text-decoration: none;
}

.auditGridItem a:hover {
  text-decoration: underline;
  text-decoration-color: var(--text-color);
}

.auditGridItem p {
  margin-top: 1em;
  text-wrap: wrap;
  line-height: 1.5em;
  text-align: justify;
  font-size: 1.5em;
  animation: slideContent 0.8s linear 0.1s backwards;
  color: var(--text-color);
}

.auditGridItem h6 {
  text-align: center;
  font-size: 2em;
  margin-left: 1em;
}

.auditServicesTitle {
  animation: slideContent 0.5s linear 0.1s backwards;
  display: flex;
  align-items: center;
}

@keyframes slideContent {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
}