label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}


input[type='submit'] {
  background-color: var(--primary-color);
  color: white;
  /* padding: 12px 20px; */
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  width: 100%;
  padding: 0.5em;
  font-size: 1em;



}

input[type='submit']:hover {
  background-color: #6a343a;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form h2 {
  color: var(--primary-color);
  text-align: left !important;
  margin-right: 2.8em;
}

.formContainer {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 100%;
  max-width: 500px
}

.formContainer h2 {
  text-align: center;
  text-transform: uppercase;
}

.col75 {
  float: left;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 1em;
  align-items: center;
}

/* Clear floats after the columns */
.row::after {
  content: '';
  display: table;
  clear: both;
}

input[type='text'],
input[type='tel'],
input[type='email'],
select,
textarea {
  width: 100%;
  padding: 12px;
  height: 3em;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}